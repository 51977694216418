import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Спортивний бар Вибагливий Хлоп
			</title>
			<meta name={"description"} content={"Ласкаво просимо до Вибагливий Хлоп, спортивного бару з родзинкою. Тут ми поєднуємо любов до спорту з невимушеною атмосферою, створюючи ідеальне місце як для вболівальників"} />
			<meta property={"og:title"} content={"Головна | Спортивний бар Вибагливий Хлоп"} />
			<meta property={"og:description"} content={"Ласкаво просимо до Вибагливий Хлоп, спортивного бару з родзинкою. Тут ми поєднуємо любов до спорту з невимушеною атмосферою, створюючи ідеальне місце як для вболівальників"} />
			<meta property={"og:image"} content={"https://carnitahustin.com/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://carnitahustin.com/media/7128098.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://carnitahustin.com/media/7128098.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://carnitahustin.com/media/7128098.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://carnitahustin.com/media/7128098.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://carnitahustin.com/media/7128098.png"} />
			<meta name={"msapplication-TileImage"} content={"https://carnitahustin.com/media/7128098.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://carnitahustin.com/media/1.jpg) center/cover"
			padding="160px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="0" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							Після довгого дня або тижня Вибагливий Хлоп - це місце, де ви можете розслабитися, поговорити про спорт, насолодитися розвагами та відпочити в хорошій компанії.
						</Text>
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
							Вибагливий Хлоп
						</Text>
						<Text as="p" font="--headline2" margin="10px 0">
							Спортивний бар
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="150px 0 0 0">
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Чому паб має велику популярність:
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Розслаблена спортивна атмосфера: Насолоджуйтесь спортивним азартом у прохолодній атмосфері, що ідеально підходить для відпочинку.
						<br />
						Дружні змагання: Беріть участь у несерйозних іграх, таких як спортивні дрібниці, змагання з міні-гольфу та дружній настільний хокей.
						<br />
						Соціальне поле: Чудове місце, щоб познайомитися з новими людьми або посміятися з нашим привітним персоналом бару, який завжди готовий поспілкуватися про останню гру або життя в цілому.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				Найближчі події
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Щопонеділка ввечері - Trivia Showdown
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Перевірте свої спортивні знання та виграйте веселі призи.
					</Text>
				</Box>
				<Image src="https://carnitahustin.com/media/2.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://carnitahustin.com/media/3.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Щоп'ятниці Фентезі-ліга
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Зберіть свою лігу, створіть команди та насолоджуйтесь спеціальними пропозиціями, поки ви розробляєте стратегію на сезон.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Вечори вдячності вболівальників
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Святкування духу спортивної майстерності зі спеціальними пропозиціями та веселою атмосферою.
					</Text>
					<Button
						href="/contact-us"
						type="link"
						text-decoration-line="initial"
						background="--color-primary"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Контакти
					</Button>
				</Box>
				<Image src="https://carnitahustin.com/media/4.jpg" display="block" width="100%" md-order="-1" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});